import React from "react";

export const WhyChooseUs = () => {
  return (
    <section id="why-choose-us" className="d-flex align-items-center bg-dark">
      <div className="container">
        <div className="why-choose-us-header">
          <h2 className="why-choose-us-header-text text-start">
            Why Choose Us
          </h2>
          <div className="why-choose-us-subheader-text text-start mt-4 mb-4" data-aos="fade-up">
            To architect, build, run, operate and manage a fintech system
            effectively and efficiently, you need:
          </div>
        </div>
        {/* <header class="section-header text-left" data-aos="fade-up">
              <h3 className="text-light">Why Choose Us</h3>
              <p className="text-light">
              To architect, build, run, operate and manage a fintech system
            effectively and efficiently, you need:
              </p>
          </header> */}
        <div className="d-flex flex-column mt-5 bg-dark">
          <div className="why-choose-us-point d-flex gap-5 flex-wrap" data-aos="fade-up">
            {/*-- Section 1 --*/}
            <div className="purple-circle">1</div>
            <div className="single-point col-md-8 col-sm-10">
              <div className="point-text-purple">
                We understand financial products and markets:
              </div>
              <div className="ul-text">
                <ul>
                  <li className="mb-3">
                    Most technology projects fail or take a lot longer to build
                    because the technology teams fail to understand the
                    financial products and markets. Our team for over three
                    decades has been building systems for global financial
                    markets and thoroughly understands financial products, what
                    they are, and the trade lifecycle.
                  </li>
                  <li className="mb-3">
                    We have built financial systems for fixed income,
                    treasuries, corporates and others, equities, interest rate,
                    money markets, credit, inflation, fx, ndf, etc. Please see
                    the diagram above for financial system components and
                    projects built by us.
                  </li>
                  <li className="mb-3">
                    Trillions of dollars' worth transactions have been executed
                    by global financial institutions on the systems built by us.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-5 mt-4 mb-5" data-aos="fade-up">
            {/*-- Section 2 --*/}
            <div className="purple-circle">2</div>
            <div className="single-point col-md-8 col-sm-10">
              <div className="point-text-purple">
                We understand technology, what works, leverage the latest
              </div>
              <div className="ul-text">
                <ul>
                  <li className="mb-3">
                    Technology evolves rapidly; we carefully blend existing
                    trusted technologies with the latest, making sure the
                    financial systems stay resilient, secure and performant.
                  </li>
                  <li className="mb-3">
                    Building systems for different markets such as equities,
                    interest rate swaps, corporate bonds, FX trading, etc.,
                    require distinct approaches. We understand and address the
                    diverse challenges of building such systems using the right
                    set of technologies to solve specific problems.
                  </li>
                  <li className="mb-3">
                    We are current with cutting edge technologies, be it Cloud,
                    SaaS, Generative AI, AI/ML, or Business Intelligence.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-5 mt-4" data-aos="fade-up">
            {/*-- Section 3 --*/}
            <div className="purple-circle">3</div>
            <div className="single-point col-md-8 col-sm-10">
              <div className="point-text-purple">
                We provide cost-efficient services
              </div>
              <div className="ul-text">
                <ul>
                  <li className="mb-3">
                    Our well-rounded team includes architects, full-stack
                    developers, DB, QA, support, system administrators, and
                    project managers. We use robust Agile and Scrum development
                    and efficient CI/CD and DevOps processes.
                  </li>
                  <li className="mb-3">
                    We Architect, Develop, Support, Operate, and Maintain
                    financial systems with unparalleled efficiency.
                  </li>
                  <li className="mb-3">
                    Our core offshore, and when needed onshore model ensures
                    cost-effectiveness without compromising quality. Our team
                    boasts over three decades of experience in constructing
                    highly efficient teams, both offshore and onshore.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
import logo from "../assets/img/logo-white.svg";
import linkedin from "../assets/img/linkedin-icon.webp";
import ursLogo from "../assets/img/urs-logo.webp";
import isoLogo from "../assets/img/iso-27001-logo.webp";
import socLogo from "../assets/img/soc2-logo.webp";

export const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-legal text-center py-5">
        <div className="container">
          <footer className="d-flex flex-wrap justify-content-between align-items-center pb-5 mb-5">
            <div className="col-lg-4 col-md d-flex align-items-center">
              <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                <img src={logo} alt="Icon" />
                {/* <svg className="bi" width="30" height="24"><use xlink:href="#bootstrap"></use></svg> */}
              </a>
            </div>

            <ul className="nav col-lg-8 col-md-8 justify-content-sm-start justify-content-md-end list-unstyled d-flex text-white">
              <li className="ms-3">
                <a href="/#what-we-do">What We Do</a>
              </li>
              <li className="ms-3">
                <a href="/#what-we-build">What We Build</a>
              </li>
              <li className="ms-3">
                <a href="/#why-choose-us">Why Choose Us</a>
              </li>
              <li className="ms-3">
                <a href="/#how-we-engage">How We Engage</a>
              </li>
              <li className="ms-3">
                <a href="/#who-we-are">Who We Are</a>
              </li>
              <li className="ms-3">
                <a href="/projects">Projects</a>
              </li>
            </ul>
          </footer>
        </div>
        <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center text-white">
          <div className="d-flex flex-column align-items-center align-items-lg-start">
            <a href="https://in.linkedin.com/company/fintechglobalcenter" target="_blank" rel="noopener noreferrer">
              <i>
                <img src={linkedin} alt="Icon" />
              </i>
            </a>
          </div>

          {/* <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
            <div className="">
                <img src={ursLogo} alt="URS Logo" className="me-2" style={{ maxHeight: '45px' }} />
                ISO 27001:2022 Certified
            </div>
          </div> */}

          {/* Certification Logos Section */}
          <div className="social-links d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center mt-3 mt-lg-0">
            <div className="d-flex align-items-center me-md-4 mb-3 mb-md-0">
              <img src={isoLogo} alt="ISO 27001:2022 Certification" height="45" className="me-2" />
              <span>ISO 27001:2022 Certified</span>
            </div>
            <div className="d-flex align-items-center">
              <img src={socLogo} alt="SOC 2 Certification" height="45" className="me-2" />
              <span>SOC2 Certified</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
